.f-css-animations {
    @for $i from 1 through 10 {
        :after:nth-child(#{$i}) {
            animation-delay: $i*0.1s;
        }
    }
  
    @for $i from 1 through 10 {
        :after:nth-child(#{$i}) {
            animation-delay: $i*0.05s;
        }
    }
  
    @for $i from 1 through 10 {
        :after:nth-child(#{$i}) {
            animation-delay: $i*0.025s;
        }
    }
  }