html,
body {
	font-family: 'Work Sans';
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	transition: background-color 0.3s ease;
	font-family: 'Work Sans';
	box-sizing: border-box;
	/* transition: 0.5s all ease; */
}
#orientation-change {
	display: none;
}
@media screen and (orientation: landscape) {
	#orientation-change {
		display: block;
		z-index: 99999;
	}
}
::-webkit-scrollbar {
	display: none;
}

@media (prefers-color-scheme: dark) {
	html {
		color-scheme: dark;
	}
	body {
		background: #ffffff;
	}
}

.sb-main-padded {
	padding: 0;
}

#buy_rent-buy,
#buy_rent-rent {
	animation-duration: 300ms;
}
#buy_rent-wrapper {
	animation-duration: 300ms;
}

@keyframes fade-away {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes move-right {
	from {
		right: 0px;
	}
	to {
		right: -300px;
		display: none;
	}
}

@keyframes move-left {
	from {
		left: 0px;
	}
	to {
		left: -300px;
		display: none;
	}
}

.MuiDrawer-root > .MuiPaper-root {
	overflow: 'visible';
}
.dotted-line {
	background-image: linear-gradient(
		to right,
		#bcbcbcbc 33%,
		rgba(255, 255, 255, 0) 0%
	);
	background-position: bottom;
	background-size: 3px 1px;
	background-repeat: repeat-x;
}

.circular-loader {
	border: 2px solid #f3f3f3; /* Light grey */
	border-top: 2px solid #069baa; /* Blue */
	border-radius: 50%;
	width: 26px;
	height: 26px;
	animation: spin 1s linear infinite;
}

.circular-loader-unlmtd {
	border: 2px solid #f3f3f3; /* Light grey */
	border-top: 2px solid #c072ac; /* Blue */
	border-radius: 50%;
	width: 26px;
	height: 26px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.roundicon-bubble {
	display: none !important;
}

@keyframes fade-in {
	0% {
		background-color: #222038
	}
	25% {
		opacity: 0.25;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

@keyframes backgroundColorTransition {
	0% {
	  	/* background: #222038; */
	  	filter: opacity(0.1);
	}
	20% {
	  	/* background: #3a2f60;  */
	  	filter: opacity(0.2);
	}
	40% {
	  	/* background: #564d85; */
	  	filter: opacity(0.4);					
	}
	60% {
		/* background: #7977a6; */
		filter: opacity(0.6);
	}
	80% {
		/* background: #b8b8d4; */
		filter: opacity(0.8);
	}
	100% {
		/* background: #ffffff; */
		filter: opacity(1);
	}
  }

  /* @keyframes smoothGradientColorShift {
	0% {
	  background: linear-gradient(90deg, #222038, #3a2f60);
	}
	25% {
	  background: linear-gradient(90deg, #3a2f60, #564d85);
	}
	50% {
	  background: linear-gradient(90deg, #564d85, #7977a6);
	}
	75% {
	  background: linear-gradient(90deg, #7977a6, #b8b8d4);
	}
	100% {
	  background: linear-gradient(90deg, #b8b8d4, #ffffff);
	}
  }
  
  .animate-background {
	animation: smoothGradientColorShift 10s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
	background-size: 200% 200%;
	background-position: center;
  }
   */

   /* @keyframes centerConvergingGradient {
	0% {
	  background: linear-gradient(90deg, #222038, #3a2f60);
	  background-size: 400% 400%;
	  background-position: 0% 50%;
	}
	25% {
	  background-position: 100% 50%;
	}
	50% {
	  background: linear-gradient(90deg, #3a2f60, #564d85);
	  background-position: 50% 0%;
	}
	75% {
	  background-position: 50% 100%;
	}
	100% {
	  background: linear-gradient(90deg, #564d85, #7977a6);
	  background-position: 0% 50%;
	}
  }
  
  .animate-background {
	animation: centerConvergingGradient 10s ease-in-out infinite;
	background-size: 400% 400%;
  }
   */

  
  


.fade-between{
	animation: backgroundColorTransition 0.3s ease-in-out ;

}

.fade-between-reverse{
	animation: backgroundColorTransition 0.3s ease-in-out;

}

.sf{
	background-color: #ffffff;
}

.unlmtd{
	background-color:'#222038';
}

#ymDivBar{
	bottom:72px !important;
	right:4px !important;
}