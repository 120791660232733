@font-face {
    font-family: 'Recline';
    src: url('/assets/Fonts/regular-recline-458134/Recline-Regular.ttf');
    /* src: url('/assets/Fonts/recline-bold/Recline-Bold.ttf'); */
    /* src: url('/assets/Fonts/medium-recline-458136/Recline-Medium.ttf'); */
    /* src: url('/assets/Fonts/semi-bold-recline-458138/Recline-SemiBold.ttf'); */
    /* src:url('../public/assets/Fonts/italic-recline-458135/Recline-Italic.ttf');
    src:url('../public/assets/Fonts/semi-bold-italic-recline-458139/Recline-SemiBoldItalic.ttf');
    font-style: normal; */
    }
   
   
  @import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');